import { useEffect, useState } from 'react';
import { BoundingBox, DisaggregatedMetric, InteractionData, LayerDetail } from '../services/types';
//import { getInteractionsLayerId } from "../utils/dashboardUtils";
import useDeepCompareEffect from './useDeepCompareEffect';

interface GeoDetails {
    lod: string;
    ids: string[]
};

const useFetchData = () => {
    // const [indicatorsData, setIndicatorsData] = useState<Metric[]>([]);
    const [tractData, setTractData] = useState<DisaggregatedMetric[]>([]); 
    const [disaggregatedIndicators, setDisaggregatedIndicators] = useState<DisaggregatedMetric[]>([]);
    const [interactionData, setInteractionData] = useState<InteractionData[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [orgId, setOrgId] = useState<number | null>(null);
    const [dashboardView, setDashboardView] = useState('');
    const [geoDetails, setGeoDetails] = useState<GeoDetails[]>([]);
    const [geoLod, setGeoLod] = useState('');
    const [boundingBox, setBoundingBox] = useState<BoundingBox | null>(null);
    const [layerDetails, setLayerDetails] = useState<LayerDetail[] | null>(null);
    const [excludeIds, setExcludeIds] = useState<string[] | null>(null);

    // const indicatorsUrl = 'https://commercedashboardbackend.caimaps.net/api/getindicators';
    const tractUrl = 'https://commercedashboardbackend.caimaps.net/api/gettractindicators';
    const interactionUrl = 'https://commercedashboardbackend.caimaps.net/api/getinteractionsummary';
    const disaggregatedUrl = 'https://commercedashboardbackend.caimaps.net/api/getdisaggregatedindicators';
    const interactionsLayerIdUrl = 'https://commercedashboardbackend.caimaps.net/api/getinteractionslayerid';

    useEffect(() => {
        const handleMessage = async (event: MessageEvent) => {
            console.log('Received message:', event.data);

            if (event.data.message === 'update') {
                const { params } = event.data;

                if (params) {
                    setOrgId(params.organization_id || 12);
                    setGeoLod(params.area_view.geo_lod || '');
                    setDashboardView(params.dashboard_view || '');
                    setGeoDetails(params.area_view.geo_details || []);
                    setBoundingBox(params.area_view.bounding_box);
                    setLayerDetails(params.layer_details || null);

                    // Fetch layer id
                    console.log("orgId: ", orgId);
                    console.log("orgId local: ", params.organization_id);
                    if(params.organization_id !== null) {


                        // Quick hack.. orgId is null for some reason. Fix later :(
                        if (params.organization_id !== 15) {
                            const layerIdResponse = await fetch(interactionsLayerIdUrl, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({ orgId: params.organization_id }),
                            });

                            if (!layerIdResponse.ok) {
                                console.log("error: ", layerIdResponse);
                                throw new Error('Network response was not ok');
                            }

                            const layerIdResult = await layerIdResponse.json();
                            const layerId = layerIdResult;
                            console.log("layerIdResult: ", layerIdResult);

                            const layer = params.layer_details?.find((layer: LayerDetail) => layer.layer_id === layerId.toString()) || null;
                            console.log("exclude ids: ", layer?.exclude_ids);
                            setExcludeIds(layer?.exclude_ids || []);
                        }
                    }
                }
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    useDeepCompareEffect(() => {
        const fetchData = async () => {
            console.log('details:', geoDetails, geoLod, orgId, dashboardView);
            // Temporary check to id number of blockgorups
            const blockGroupDetails = geoDetails.find((detail: any) => detail.lod === 'block group');
            if (blockGroupDetails) {
                console.log('Number of block groups selected:', blockGroupDetails?.ids?.length);
            }
            
            try {
                if (orgId !== null) {
                    setLoading(true);

                    // For now we will fetch all metrics through the disaggregate call
                    // Fetch indicators data
                    // const indicatorsResponse = await fetch(indicatorsUrl, {
                    //     method: 'POST',
                    //     headers: {
                    //         'Content-Type': 'application/json',
                    //     },
                    //     body: JSON.stringify({ geoDetails, geoLod, orgId, dashboardView }),
                    // });

                    // if (!indicatorsResponse.ok) {
                    //     throw new Error('Network response was not ok');
                    // }

                    // const indicatorsResult = await indicatorsResponse.json();
                    // setIndicatorsData(indicatorsResult.data);

                    // Fetch tract data
                    const tractResponse = await fetch(tractUrl, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ geoDetails, geoLod, orgId, dashboardView }),
                    });

                    if (!tractResponse.ok) {
                        throw new Error('Network response was not ok');
                    }

                    const tractResult = await tractResponse.json();
                    console.log('tract result:', tractResult);
                    setTractData(tractResult.data);
                    

                    // Fetch disaggregated data
                    const disaggregatedResponse = await fetch(disaggregatedUrl, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ geoDetails, geoLod, orgId, dashboardView }),
                    });

                    if (!disaggregatedResponse.ok) {
                        throw new Error('Network response was not ok');
                    }

                    const disaggregatedResult = await disaggregatedResponse.json();
                    setDisaggregatedIndicators(disaggregatedResult.data);

                    // Fetch interaction data if the current dashboard view is interactions
                    if (dashboardView === 'interactions') {
                        // Fetch layer id
                        const layerIdResponse = await fetch(interactionsLayerIdUrl, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({ orgId }),
                        });

                        if (!layerIdResponse.ok) {
                            throw new Error('Network response was not ok');
                        }

                        const layerIdResult = await layerIdResponse.json();
                        console.log("layerIdResult: ", layerIdResult);
                        const layerId = layerIdResult;

                        const intLayer = layerDetails?.find((layer: LayerDetail) => layer.layer_id === layerId) || null;
                        const startDate = intLayer?.start_date;
                        const endDate = intLayer?.end_date;

                        const interactionResponse = await fetch(interactionUrl, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            //body: JSON.stringify({ boundingBox, excludeIds, layerId }),
                            body: JSON.stringify({ boundingBox, excludeIds, layerId, startDate, endDate }),
                        });

                        // Hack to get all interactions so we can calculate how many we are not showing
                        const allInteractionResponse = await fetch(interactionUrl, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({ layerId }),
                        });
                        
                        if (!interactionResponse.ok || !allInteractionResponse.ok) {
                            throw new Error('Network response was not ok');
                        }
                        
                        const interactionResult = await interactionResponse.json();
                        const allInteractionResult = await allInteractionResponse.json();

                        // We need to sneak in a parameter to get our 'missing' count
                        console.log("interactionResult: ", interactionResult);
                        if(interactionResult.results > 0){
                            interactionResult.data[0].item_missing_count = allInteractionResult.data[0].item_count - interactionResult.data[0].item_count;
                        }

                        setInteractionData(interactionResult.data);
                    }
                }
            } catch (error: any) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [geoDetails, geoLod, orgId, dashboardView, boundingBox, excludeIds]);

    return { tractData, disaggregatedIndicators, interactionData, loading, error, dashboardView };
};

export default useFetchData;