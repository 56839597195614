import { useNavigate } from "react-router-dom";
import { Key, useEffect, useState } from "react";
import useFetchData from "../hooks/useFetchData";
import useFetchAllIndicators from "../hooks/useFetchAllIndicators";
import fetchBLSTRSData from "../hooks/useFetchBLSTRSData";
import { formatValue } from "../utils/helpers";
import { categorizeData, getSvgMap, getMetricGroupOrderIds, getMetricGroupChartId, getCountyIds } from "../utils/dashboardUtils";
import MetricCard from "../components/MetricCard";
import OwnershipCard from "../components/OwnershipCard";
import IndustryCategoryChart from "../components/IndustryCategoryChart";
import DisaggregateMetricCard from "../components/DisaggregateMetricCard";
import EmploymentByIndustryChart from "../components/EmploymentByIndustryChart";
import IndustryTRSBLSCharts from "../components/IndustryBLSTRSCharts";
import MetricsModal from "./MetricsModal";
import LegendMetricModal from "../components/LegendMetricModal";
import demoSvg from '../assets/demo.svg';
import workforceSvg from '../assets/workforce.svg';
import economySvg from '../assets/economy.svg';
import interactionsSvg from '../assets/interactions-tab.svg';
import jobsSvg from '../assets/jobs.svg';
import RaceEthnicityChart from "../components/RaceEthnicityChart";
import ImmigrationChart from "../components/ImmigrationChart";
import HouseholdIncomeChart from "../components/HouseholdIncomeChart";
import LoadingIndicator from "../components/LoadingIndicator";

const tabs = ["Demographics", "Workforce", "Economy", "Interactions"];

const tabSvgMap: { [key: string]: string } = {
    "Demographics": demoSvg,
    "Workforce": workforceSvg,
    "Economy": economySvg,
    "Interactions": interactionsSvg,
};

const Dashboard = () => {
    const isIframe = window.self !== window.top;
    const navigate = useNavigate();

    const { data: blsTrsData, loading: BLSLoading }: any = fetchBLSTRSData(getCountyIds());
    const { tractData, disaggregatedIndicators, interactionData, loading, dashboardView } = useFetchData();
    const { disaggregatedIndicators: disaggData, interactionData: interaction, loading: allIndicatorsLoading } = useFetchAllIndicators();

    const isDataLoading = BLSLoading || allIndicatorsLoading || loading;

    // Combine data from all sources
    const allDataAvailable = !isDataLoading && tractData && disaggregatedIndicators;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalUrl, setModalUrl] = useState('');
    const [logoUrl, setLogoUrl] = useState('');
    const [legendUrl, setLegendUrl] = useState('');
    const [selectedArea, setSelectedArea] = useState('');
    const [siteUrl, setSiteUrl] = useState('');
    const [legendId, setLegendId] = useState(0);
    const [isLegendModalOpen, setIsLegendModalOpen] = useState(false);
    

    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
          if (event.data.message === 'pdf') {
            setModalUrl(event.data.params.url);
            setLogoUrl(event.data.params.logoUrl || '');
            setLegendUrl(event.data.params.legendUrl || '');
            setSelectedArea(event.data.params.selectedArea || '');
            setSiteUrl(event.data.params.siteUrl || '');
            setIsModalOpen(true);
            
            // Send message to expand
            window.parent.postMessage({ message: "expand" }, '*');
            console.log('expand message sent');
          } else if (event.data.message === 'metric-modal') {

            // Legend metadata info icon event
            console.log('Message received from parent app inside legend metric modal:', event.data);
            setLegendId(event.data.params.id);
          }
        };
    
        window.addEventListener('message', handleMessage);
    
        return () => {
          window.removeEventListener('message', handleMessage);
        };
      }, []);

    const closeLegendModal = () => {
        setIsLegendModalOpen(false);
        setLegendId(-1);
    };

    const closeModal = (e: React.MouseEvent<HTMLButtonElement>) => {
        let commerceOrigin = '*';

        e.preventDefault();
        setIsModalOpen(false);
        // Send message to expand
        window.parent.postMessage({ message: "expand" }, commerceOrigin);
        console.log('expand message sent');
    };

    // Convert lowercase dashboardView to title case
    const formattedTab = tabs.find(t => t.toLowerCase() === dashboardView) || tabs[0];
    const activeTab = formattedTab;

    const categorizedData = categorizeData(tractData, disaggregatedIndicators);

    const renderMetrics = (tab: string) => {

        switch(tab) {
            case "Demographics":
                // If data is not loaded, display a loading indicator
                if (loading) {
                    return <LoadingIndicator />;
                }

                const demoOrder = getMetricGroupOrderIds(tab);  // [383, 387, 385]; // Metric IDs for Population, Households under 200% Poverty, Limited English Proficiency

                const sortedDemoMetrics = categorizedData.Demographics
                    .filter(metric => demoOrder.includes(metric.id)) // Filter the metrics
                    .sort((a, b) => demoOrder.indexOf(a.id) - demoOrder.indexOf(b.id)); // Sort the metrics based on the defined order

                return (
                    <div className="flex flex-wrap">
                        <div className="flex w-full">
                            {sortedDemoMetrics.map(metric => (
                                <MetricCard
                                    key={metric.id}
                                    id={metric.id}
                                    title={metric.name}
                                    value={metric.versions[0].value}
                                    unit={metric.versions[0].unit}
                                    svg={getSvgMap(metric.id)}
                                />
                            ))}
                        </div>
                        <div className="flex w-full">
                            {categorizedData.Demographics.filter(metric => 
                                [getMetricGroupChartId(tab, "race")].includes(metric.id) // Race & Ethnicity
                            ).map(metric => (
                                <DisaggregateMetricCard
                                    key={metric.id}
                                    id={metric.id}
                                    title={metric.name}
                                    subText=""
                                    svg={getSvgMap(metric.id)}
                                />
                            ))}
                        </div>
                        <div className="w-full">
                        <RaceEthnicityChart disaggregatedData={disaggregatedIndicators}/>
                        </div>
                        <div className="flex w-full">
                            {categorizedData.Demographics.filter(metric => 
                                [getMetricGroupChartId(tab, "immigration")].includes(metric.id) // Top Countries of Immigration
                            ).map(metric => (
                                <DisaggregateMetricCard
                                    key={metric.id}
                                    id={metric.id}
                                    title={metric.name}
                                    subText=""
                                    svg={getSvgMap(metric.id)}
                                />
                            ))}
                        </div>
                        <div className="ml-32">
                            <ImmigrationChart disaggregatedData={tractData}/>
                        </div>
                    </div>
                );
            case "Workforce":
                // If data is not loaded, display a loading indicator
                if (loading) {
                    return <LoadingIndicator />;
                }

                const workforceOrders = getMetricGroupOrderIds(tab);

                const sortedWorkforceMetrics = workforceOrders.map((order: number[]) => 
                    categorizedData.Workforce
                        .filter(metric => order.includes(metric.id))
                        .sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id))
                );

                // We need to create the text under the hhinc chart dynamically with unit_descriptor
                const hhIncomeData = disaggregatedIndicators.find(data => data.id === getMetricGroupChartId("Workforce", "mhhinc"));
                console.log('hhincome data:', hhIncomeData);

                return (
                    <div className="flex flex-wrap">
                        {sortedWorkforceMetrics.map((sortedGroup: any[], index: Key | null | undefined) => (
                            <div className="flex w-full" key={index}>
                                {sortedGroup.map(metric => (
                                    <MetricCard
                                        key={metric.id}
                                        id={metric.id}
                                        title={metric.name}
                                        value={metric.versions[0].value}
                                        unit={metric.versions[0].unit}
                                        svg={getSvgMap(metric.id)}
                                    />
                                ))}
                            </div>
                        ))}
                        <div className="flex w-full mt-2">
                            {categorizedData.Workforce.filter(metric => 
                                [getMetricGroupChartId(tab, "mhhinc")].includes(metric.id) // HHIncome
                            ).map(metric => (
                                <DisaggregateMetricCard
                                    key={metric.id}
                                    id={metric.id}
                                    title={metric.name}
                                    subText=""
                                    svg={getSvgMap(metric.id)}
                                />
                            ))}
                        </div>
                        {hhIncomeData && 
                        <div className="flex flex-col ml-[6.7rem]">
                            <HouseholdIncomeChart disaggregatedData={disaggregatedIndicators}/>
                            <div className="flex justify-start ml-[8.5rem]">
                                <p className="text-xs text-color-cai-dashboard">{hhIncomeData?.versions[0].unit_descriptor}</p>
                            </div>
                        </div>
                        }
                    </div>
                );
            case "Economy":
                // If data is not loaded, display a loading indicator
                if (loading) {
                    return <LoadingIndicator />;
                }

                const econIds = getMetricGroupOrderIds(tab);
                console.log("economy counties: ", getCountyIds());

                const industryData = disaggregatedIndicators.find(data => data.id === getMetricGroupChartId("Economy", "empind"));

                return (
                    <div className="flex flex-wrap">
                        {econIds.length > 0 && 
                        <div className="flex w-full">
                            {categorizedData.Economy.filter(metric => 
                                econIds.includes(metric.id) // Metric ID for Employment by Industry
                            ).map(metric => (
                                <DisaggregateMetricCard
                                    key={metric.id}
                                    id={metric.id}
                                    title={metric.name}
                                    subText="Total Jobs:"
                                    value={metric.versions[0].value}
                                    unit={metric.versions[0].unit}
                                    svg={getSvgMap(metric.id)}
                                />
                            ))}
                        </div>
                        }
                        {industryData && <EmploymentByIndustryChart disaggregatedData={disaggregatedIndicators}/>}
                        {blsTrsData.blsFirmsData.length > 0 && blsTrsData.blsJobsData.length > 0 && blsTrsData.trsData.length > 0 && 
                        <div className="flex w-full mt-[2rem]">
                            <IndustryTRSBLSCharts data={blsTrsData}/>
                        </div>
                        }
                    </div>
                );
            case "Interactions":
                const isInteractionDataLoaded = (data: any[]) => {
                    return data.length > 0;
                }

                // If data is not loaded, display a loading indicator
                if (!isInteractionDataLoaded(interactionData)) {
                    return <LoadingIndicator />;
                }

                const classificationTally = interactionData[0]?.attribute_tallies.find(tally => tally.name === "Classification");
                return (
                    <div className="flex flex-wrap">
                        <h2 className="text-color-cai-dashboard inline-block text-[.9rem] ml-[1rem] -mt-[1rem] mr-[1rem]">{ interactionData[0].item_missing_count == null || interactionData[0].item_missing_count == 0 ? 'Showing all interactions. Enable layer to filter interactions by selected area.' : 'Interactions filtered to selected area on the map.' }</h2>
                        <div className="flex w-3/4 item-center justify-between ml-20 my-8">
                            <div className="mr-[3rem]"> 
                                <img src={interactionsSvg} alt={`Interactions icon`} className="ml-1 w-10 h-10 text-blue-500" />
                                <div className="flex flex-col items-start w-full">
                                    <div className="flex items-center w-full justify-between">
                                        <h2 className="text-color-cai-dashboard text-2xl font-bold">{formatValue(Number(interactionData[0].item_count), 'integer')}</h2>
                                    </div>
                                    <p className="text-color-cai-gray text-sm font-bold w-[7.5rem]">Interactions</p>
                                </div>
                                <p className={`text-[11px] -m-[4rem] mt-[2rem] inline-block ${ interactionData[0].item_missing_count == null || interactionData[0].item_missing_count == 0 ? 'hidden' : '' }`}>{formatValue(Number(interactionData[0].item_missing_count), 'integer')} interactions are outside of the selected area.</p>
                            </div>
                            <div className="">
                                <img src={jobsSvg} alt={`Jobs icon`} className="ml-1 w-10 h-10 text-blue-500" />
                                <div className="flex flex-col items-start w-full">
                                    <div className="flex items-center w-full justify-between">
                                        <h2 className="text-color-cai-dashboard text-2xl font-bold">{formatValue(Number(interactionData[0].item_total), 'integer')}</h2>
                                    </div>
                                    <p className="text-color-cai-gray text-sm font-bold w-[7.5rem]">Jobs</p>
                                </div>
                            </div>
                        </div>
                        {interactionData[0].attribute_tallies.length ?
                            <div>
                                <p className="mt-2 ml-3 text-color-cai-gray text-sm font-bold">Ownership Details</p>
                                <div className="flex flex-wrap shrink-0">
                                    {classificationTally?.tallies.map((tally, index) => (
                                        <OwnershipCard
                                            key={index}
                                            title={tally.name}
                                            value={tally.value}
                                            total={parseInt(interactionData[0].item_count)}
                                            unit="percentage"
                                        />
                                    ))}
                                </div>
                                <IndustryCategoryChart interactionData={interactionData}/>
                            </div> : ''
                        }
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div>
            <div className="flex mr-4 items-center justify-end ">
                {/* <button onClick={() => console.log('hi')} className="transition duration-100 ease-in-out transform hover:scale-105 p-2 rounded">
                    <img src={demoSvg} alt="Print button" className="h-6 w-6"></img>
                </button> */}
            </div>
           {!isIframe && <div className="flex m-4 items-center no-print">
                {tabs.map(tabName => (
                    <button
                        key={tabName}
                        className={`flex flex-col items-center w-32 px-4 py-2 rounded-md text-xs ${activeTab === tabName ? 'bg-color-cai-dashboard text-white' : 'bg-white text-color-cai-dashboard'}`}
                        onClick={() => navigate(`/dashboard/${tabName}`)}
                    >
                        <img
                            src={tabSvgMap[tabName]}
                            alt={`${tabName} icon`}
                            className={`w-10 h-10 ${activeTab === tabName ? 'text-white' : 'text-gray-500'}`}
                            style={{ filter: activeTab === tabName ? 'invert(100%)' : 'none' }} // White for active tab
                        />
                        {tabName}
                    </button>
                ))}
            </div> }
            
            <div className="m-4 no-print">
                {allDataAvailable ? renderMetrics(activeTab) : <div className="loading-container">
                    <div className="loading-spinner"></div>
                </div>} {/* Delay rendering until loading is false */}
            </div>
            <LegendMetricModal
                id={legendId}
                isOpen={isLegendModalOpen}
                onClose={closeLegendModal}
                svg={getSvgMap(legendId)}
            />

            {isModalOpen && <MetricsModal
                isOpen={isModalOpen}
                onClose={closeModal}
                tractData={tractData}
                disaggregatedData={disaggData}
                interactionData={interaction}
                pdfUrl={modalUrl}
                logoUrl={logoUrl}
                legendUrl={legendUrl}
                selectedArea={selectedArea}
                siteUrl={siteUrl}
            />}
        </div>
    );
}

export default Dashboard;